<template>
  <div>
    <el-main>
      <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.plat.resource.folder.title.addHeadTitle')" @head-save="headSave('save')"
                   @head-cancel="headCancel"  @head-save-back="headSave('back')">
      </head-layout>

      <form-layout :column="column" :dataForm.sync="dataObj" ref="formLayout"></form-layout>
    </el-main>
  </div>
</template>

<script>
import {$t} from "@/lang";
import { optionParent, optionChild } from "@/option/system/dict";
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {getList,add} from "@/api/resource/folder";

export default {
  name: "folderEdit",
  components: { FormLayout, HeadLayout },
  data(){
    return{
      optionParent: optionParent(this),
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        },{
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      column:[
        {
          label:this.$t("cip.plat.resource.folder.field.name"),
          prop: "folderName",
          labelWidth: 150,
          search: true,
          rules: [
            {
              required: true,
              message: $t('cip.cmn.rule.inputWarning') + this.$t("cip.plat.resource.folder.field.name"),
              trigger: "blur"
            }
          ]
        },
        {
          label:this.$t("cip.plat.resource.folder.field.code"),
          prop: "folderCode",
          search: true,
          labelWidth: 150,
          rules: [
            {
              required: true,
              message: $t('cip.cmn.rule.inputWarning') + $t("cip.plat.resource.folder.field.code"),
              trigger: "blur"
            }
          ]
        },
        {
          label: this.$t("cip.plat.resource.folder.field.parentId"),
          prop: "parentId",
          type: "tree",
          labelWidth: 150,
          dicUrl: '/api/sinoma-resource/sysfiletree/treeList',
          hide: true,
          addDisabled: false,
          props: {
            label: "folderName",
            value: 'id'
          },
          rules: [
            {
              required: false,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.resource.folder.field.parentId"),
              trigger: "click"
            }
          ]
        },
      ],
     dataObj: {

     },
    }
  },
  methods:{
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headSave(type) {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading()
          add(this.dataObj).then((res) => {
            if(type=='back'){
              this.headCancel()
            }
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.dataObj = res.data.data
          }, error => {
            window.console.log(error);

            this.$refs.formLayout.$refs.form.allDisabled = false
          }).finally(()=>{
            this.$loading().close()
          })
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }


      })

    },
  },
  created() {
    getList().then(res=>{
      const column = this.findObject(this.column, "parentId");
      column.dicData = res.data.data;
    })
  }
}
</script>

<style scoped>

</style>
